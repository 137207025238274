import ls from 'local-storage'
import { add } from '../actions/environment'
import { loadExecution } from '../actions/activity'
import checkSession from '../utils/session'
import {toastr} from 'react-redux-toastr'
const API_URL = process.env.API_URL;

function getHeaders(){
    const token = ls.get('token',null);
    const headers = new Headers();
    headers.append("authorization", token); 
    headers.append("Content-Type", "application/json"); 
    return headers;
}

export default class EnvironmentApi {
    
    static async loadListEnvironments(data) {

        
        const params = { 
            method: 'POST',
            headers: getHeaders(),
            cache: 'no-cache',
            body: JSON.stringify(data)
        };
        
        const url = API_URL + "/environment/list";
        
        return fetch(url,params)
        .then(response => response.json())
        .then(json => {
            checkSession(json);
            return json.data;
        })
        .catch(function(error) {
            console.log(error); 
            return null
        });

    }

    
    static loadEnvironment(data){

            const params = { 
                method: 'POST',
                headers: getHeaders(),
                cache: 'no-cache',
                body: JSON.stringify(data)
            };
            
            const url = API_URL + "/environment/view";
            
            return fetch(url,params)
            .then(response => response.json())
            .then(json => {
                console.log("vindo do ambiente", json);
                checkSession(json);
                return json.data;
            })
            .catch(function(error) {
                console.log(error); 
                return undefined
            });
    }
    
    static add(data){
        return dispatch => {
            
            var params = { 
                method: 'POST',
                headers: getHeaders(),
                cache: 'no-cache',
                body: JSON.stringify(data)
            };
            
            const url = API_URL + "/environment/add";
            
            fetch(url,params)
            .then(response => response.json())
            .then(json => {
                
                if(parseInt(json.status) === 201){
                    toastr.success('Novo Ambiente', json.msg);
                }else{
                    toastr.error('Novo Ambiente', json.msg);
                }
                
                checkSession(json);
                dispatch(add());
            })
            .catch(function(error) {
                console.log(error); 
            });
        }
    }
    

    static activitiesStatus(data){
        return dispatch => {

            var params = { 
                method: 'POST',
                headers: getHeaders(),
                cache: 'no-cache',
                body: JSON.stringify(data)
            };
            
            const url = API_URL + "/activities/execution";
            
            console.log("URL", url)
            fetch(url,params)
            .then(response => response.json())
            .then(json => {
                console.log("Resposta das activities", json);
                checkSession(json);
                dispatch(loadExecution(json.data));
            })
            .catch(function(error) {
                console.log(error); 
            });
        }
    }
}