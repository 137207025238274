/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import Progress from '../../../components/component-progress';
import {mdiAccountGroup , mdiDeveloperBoard, mdiCalendarCheckOutline } from '@mdi/js'
import Icon from '@mdi/react'
export default function PageEnvironments({data}) {

    const [users, serUsers] = useState({on: 0, off:0})
    const [tasks, setTasks] = useState({done: 0, total:0})
    const [environments] = useState(data.length)

    const Header = styled.div`
            position: relative;
            float: left;
            width: 100%;
            padding: 2em 2em 2em 2em;
            box-sizing: border-box;
            
            h1{
                position: relative;
                float: left;
                color: rgb(49,49,49);
                font-size: 1.4rem;
                width: auto;
                font-weight:600;
                text-transform:uppercase;
            }

            ul{
                position: relative;
                float:left;
                margin: 1em 0 0 0;
                @media only screen and (min-width: 46.001em)   { 
                    margin: 0;
                }
            }
            
            li{
                padding: 1em 1.5em;
                border-radius: .2em;
                box-shadow: 0 0.25em 0.5em rgb(49 49 49 / 5%);
                color: white;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                width: 100%;
                margin: 0 0 1em 0;

                @media only screen and (min-width: 46.001em)   { 
                    margin: 1em 1em 0 0;
                    width: auto;
                    justify-content: flex-start;
                }


                .text, svg{
                    position: relative;
                    margin: auto 0;
                }

                
                svg{
                    width: 32px;
                    height: 32px;
                    margin-right:1em;
                }

                p, label{
                    text-transform:uppercase;
                    font-weight:600;
                    color: white;
                }
                p{
                    font-size: 1.2rem;
                    span{
                        opacity: .75;
                        font-size:1rem;
                    }
                }

                .component-progress{
                    position:relative;
                    float: left;
                    margin: .25em 0;

                    .progress-view{
                        background: rgba(255, 255, 255, 0.35);
                        div{
                            background: white;
                        }
                    }
                }

                label{
                    position:relative;
                    float: left;
                    font-size: 0.74rem;
                    margin-top:.4em;
                    font-weight: 500;
                }
            }

            li.users{
                background: #56ab2f;  /* fallback for old browsers */
                background: -webkit-linear-gradient(to left, #63e09d, #56ab2f);  /* Chrome 10-25, Safari 5.1-6 */
                background: linear-gradient(to left, #63e09d, #56ab2f); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
            }

            li.tasks{
                background: #f7a1a1;  /* fallback for old browsers */
                background: -webkit-linear-gradient(to right, #EF629F, #f7a1a1);  /* Chrome 10-25, Safari 5.1-6 */
                background: linear-gradient(to right, #EF629F, #f7a1a1); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
            }

            li.environments{
                background: #fc4a1a;  /* fallback for old browsers */
                background: -webkit-linear-gradient(to left, #f7b733, #fc4a1a);  /* Chrome 10-25, Safari 5.1-6 */
                background: linear-gradient(to left, #f7b733, #fc4a1a); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
            }

    `;

    useEffect(() => {

        let u = {on: 0, off:0}
        let t = {done: 0, total:0}

        for (let i = 0; i < data.length; i++) {
            const environment = data[i];
            u.on += environment.users.on
            u.off += environment.users.off
            t.total += environment.schedules.total
            t.done += environment.schedules.done
        }

        serUsers(u)
        setTasks(t)
        
    }, [data])


    const defaultOptions = {
        display: false,
        duration: 2,
    }
    
    return (
        <Header>
            <h1>Ambientes</h1>
            <ul>
                <li className='users'>
                    <Icon path={mdiAccountGroup }/>
                    <div className='text'>
                        <p><span> {users.on} </span>/ {users.off + users.on}</p>
                        <label>Usuários</label>

                        <Progress value={users.on} total={users.off + users.on} options={defaultOptions}/>

                    </div>
                </li>
                <li className='tasks'>
                    <Icon path={mdiCalendarCheckOutline }/>
                    <div className='text'>
                        <p><span>{tasks.done}</span> / {tasks.total}</p>
                        <label>Tarefas</label>

                        <Progress value={tasks.done} total={tasks.total} options={defaultOptions}/>

                    </div>
                </li>
                <li className='environments'>
                    <Icon path={mdiDeveloperBoard}/>
                    <div className='text'>
                        <p>{environments}</p>
                        <label>Ambientes</label>
                    </div>
                </li>
            </ul>

        </Header>
    ); 
}
    
