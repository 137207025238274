import React, { Component } from 'react';
import {BrowserRouter, Route, Switch, Redirect} from "react-router-dom";
import ReduxToastr from 'react-redux-toastr'
import LoginForm from '../components/login-form'
import LogoutPage from '../pages/logout'
import CadastreForm from '../components/cadastre-form'
import { isAuthenticated } from "../config/auth"
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import App from '../App';
import configureStore, { history } from '../config/configureStore'
const store = configureStore()

const PrivateRoute = ({component: Component, ...rest}) => (
    <Route {...rest}  
        render={ props => (
        isAuthenticated() === true ? (   
            <Component {...props} />
        ) : (                
            <Redirect to={{ pathname:"/login", state: {from: props.location}}} />
        )
    )}/>
);

class Routes extends Component {
    render(){
        return(
            <Provider store={store}>
                <ConnectedRouter history={history}> 
                    <BrowserRouter basename="/">
                        <Switch>
                            <Route path="/login" component={LoginForm} />
                            <Route path="/cadastre" component={CadastreForm} />
                            <Route path="/logout" component={LogoutPage} exact={true} />
                            <PrivateRoute path="/" component={App}/>
                        </Switch>
                    </BrowserRouter>
                </ConnectedRouter>
                <ReduxToastr
                    timeOut={4000}
                    newestOnTop={false}
                    preventDuplicates
                    position="top-right"
                    transitionIn="bounceIn"
                    transitionOut="bounceOut"
                    progressBar
                    closeOnToastrClick/>
            </Provider>
        )
    }
}


export default Routes;