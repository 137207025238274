import React from 'react'

export default function NotFound() {

    return (
        <div className='pg' id='Page404'>
            <div className='container'>
                <h1>404</h1>
                <h2>Página Não Encontrada</h2>
                <p>Infelizmente essa página não foi encontrada, mas não fique triste continue navegando =)</p>
            </div>
        </div>
    ); 
}
