export function load(model = []){
    return { 
        type:'LOAD',
        model:model
    }
}

export function loadList(environments = []){
    return { 
        type:'LOAD_LIST',
        environments:environments
    }
}
            
export function add(data = {}){
    return { 
        type:'ADD',
        data:data
    }
}




  