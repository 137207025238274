export function load(account){
    return { 
        type:'LOAD_SIDEMENU',
        account:account
    }
}

export function openConfig(isOpen){
    return { 
        type:'OPEN_CONFIG',
        isOpen : isOpen
    }
}

export function openSearch(isOpen){
    console.log("actions - openSearch");
    return { 
        type:'OPEN_SEARCH',
        openSearch : isOpen
    }
}

export function openNew(isOpen){
    return { 
        type:'OPEN_NEW',
        openNew : isOpen
    }
}

export function openUserMenu(isOpen){
    return { 
        type:'OPEN_USER_MENU',
        isOpen : isOpen
    }
}

export function selectedClient(client){
    return { 
        type:'CHANGE_CLIENT',
        clientSelected : client
    }
}
  