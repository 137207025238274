import ls from 'local-storage'
import checkSession from '../utils/session'
import {toastr} from 'react-redux-toastr'
const API_URL = process.env.API_URL;


function getHeaders(){
    const token = ls.get('token',null);
    const headers = new Headers();
    headers.append("authorization", token); 
    headers.append("Content-Type", "application/json"); 
    return headers;
}

export default class ClientApi {
    
    static async listClientByAccount() {
      
            const params = { 
                method: 'POST',
                headers: getHeaders(),
                cache: 'no-cache'
            };
            
            const url = API_URL + "/client/list";

            return fetch(url,params)
            .then(response => response.json())
            .then(json => {
                checkSession(json);
                console.log("retorno da lista de clientes", json);
                return json.data;
            })
            .catch(function(error) {
                console.log(error); 
                return []
            });
    }

    static add(data){
          
        const params = { 
            method: 'POST',
            headers: getHeaders(),
            cache: 'no-cache',
            body: JSON.stringify(data)
        };
        
        const url = API_URL + "/client/add";
        
        fetch(url,params)
        .then(response => response.json())
        .then(json => {
            if(parseInt(json.status) === 201){
                toastr.success('Novo Cliente', json.msg);
            }else{
                toastr.error('Novo Cliente', json.msg);
            }
            checkSession(json);
            return json.data
        })
        .catch(function(error) {
            console.log(error); 
        });
    }

    static edit(data){
            const params = { 
                method: 'POST',
                headers:  getHeaders(),
                cache: 'no-cache',
                body: JSON.stringify(data)
            };
            
            const url = API_URL + "/client/edit";
            
            fetch(url,params)
            .then(response => response.json())
            .then(json => {
                if(parseInt(json.status) === 201){
                    toastr.success('Atualizar Cliente', json.msg);
                }else{
                    toastr.error('Atualizar Cliente', json.msg);
                }
                
                checkSession(json);
                return json.data;
            })
            .catch(function(error) {
                console.log(error); 
            });
    }

    static load(id){
        const params = { 
            method: 'POST',
            headers:  getHeaders(),
            cache: 'no-cache',
            body: JSON.stringify({id:id})
        };
        
        const url = API_URL + "/client/load";
        
        fetch(url,params)
        .then(response => response.json())
        .then(json => {
            checkSession(json);
            return json.data;
        })
        .catch(function(error) {
            console.log(error); 
        });
    }

    static listClientByView() {
        // Clientes
        // Usuários cadastrados
        // Valor dos ambientes.
        // Quantidade de usuários nos ambientes.
    }

}