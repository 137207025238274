/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import ls from 'local-storage'
import ReportList from '../../components/report/report-list';
import "react-datepicker/dist/react-datepicker.css"
import reportApi from '../../controllers/reportApi'
import ReportForm from './form'
import ComponentLoadingPage from '../../components/ComponentLoadingPage'

export default function PageEnvironments() {

    const [data, setData] = useState([])
    const [openForm, setOpenForm] = useState(false)
    const [loading, setLoading] = useState(true)

    const refresh = async () => {
        const user = ls.get("user", null);
        const reports = await reportApi.load({"clientId":user.client.id});
        setData(reports);
        setLoading(false)
    };

    const openFormChange = () => {
        setOpenForm(!openForm)
    }
	
    useEffect(() => {
        refresh()
    }, [])

    const save = async (report) => {
        await reportApi.add(report)
        setOpenForm(false);
        refresh()
    }
    
    return (
        <div id="report" className="pg" >
            {
            (loading) ? (
                <ComponentLoadingPage/>
            ) : (
            <div className='content'>
                <div className="panel">
                    <h1>Relatórios</h1>
                    <button onClick={openFormChange}>Novo</button>
                </div>
                
               <ReportList data={data}/>
                
                <div className={"bk-content " + (openForm ? 'on' : '')} onClick={openFormChange}></div>

                <ReportForm 
                    openForm={openForm}
                    save={save}
                />
                    
            </div>
            )
        }
        </div>
    ); 
}
    
