export default function environment(state = [],action){
    switch (action.type) {
        case 'LOAD':
            return {model:action.model};

        case 'LOAD_LIST':
            return {environments:action.environments};

        case 'ADD':
            return {new:action.data};    
            
        default:
            return state;
    }

}