import React, {useEffect, useState} from 'react'
import styled, {keyframes} from 'styled-components'
import CountUp from 'react-countup';
import logo from '../asserts/logo/logo-icon.svg'

const themebase = '#fafafa';

const breatheAnimation = keyframes`
 0% { opacity: 0; transform:scale(.75); }
 50% { opacity: 1; transform:scale(1); }
 100% { opacity: 0; transform:scale(.75); }
`;

const Fade = styled.div`
		position: absolute;
		left: 0;
		right: 0;
		height: 100%;
    width: 100%;
    will-change: transform;
    transition-property: all;
    transition-duration: .25s;
    transition-timing-function: cubic-bezier(.55,0,.1,1);
		background-color: ${themebase};
		display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
		 
		img{
			position: relative;
			margin: auto;
			width: 80px;
			height: 80px;
			animation-name: ${breatheAnimation};
			animation-duration: 2s;
			animation-iteration-count: infinite;
			}
`;

export default function ComponentProgress({value, total, progress, options}) {
    return (
        <Fade>
					<img src={logo} alt='logo'/>
				</Fade>
    ); 
}
    
