export default function client(state = [],action){
    switch (action.type) {
        case 'LIST':
            return {list:action.data};

        case 'ADD':
            return {new:action.data};

        case 'EDIT':
            return {edit:action.data};

        case 'LOAD':
            return {client:action.client};
            
        default:
            return state;
    }

}