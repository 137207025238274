import React, {useEffect} from 'react'
import ls from 'local-storage'


export default function Logout() {

    useEffect(() => {
        ls.clear()
        window.location.href = "/"
    })

    return (
        <div className='pg' id='Page404'>
            <div className='container'>
                <h1>Saindo...</h1>
            </div>
        </div>
    ); 
}

