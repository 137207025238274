import React, {Component} from 'react'
import { connect } from 'react-redux'
import { NavLink} from 'react-router-dom'
const CLIENT_ID = 'puQp2imeJbP8xVLayPPjVSRHH4qKt8RM'
const REDIRECT_URI = 'https://back-dashboard.laivon.com/contaazul/oauth-integration'

const generateRandomString = function(length) {
    var text = '';
    var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    
    for (var i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
}

class IntegrationsPage extends Component{
    constructor(props) {
        super(props);
        this.state = {
            clients:[],
            modelClient:null,
            urlContaAzul:""
        };
    }
    
    componentDidMount(){
        let url = "https://api.contaazul.com/auth/authorize?redirect_uri=" + REDIRECT_URI;
        url += "&client_id=" + CLIENT_ID + "&scope=sales&state=" + generateRandomString()
        this.setState({urlContaAzul:url})
    }
    
    componentWillReceiveProps(newprops){
       
    }

    render(){
        return (
            <div id="integrations" className="pg">
                <div className='content'>

                    <div className="panel">
                        <h1>Integrações</h1>
                    </div>

                    <ul className="list-integrations">
                        <li>
                            <NavLink to="/integrations/contaazul" activeClassName="on"> 
                                <img alt="integração conta azul" src="https://e3ba6e8732e83984.cdn.gocache.net/uploads/image/file/72484/regular_conta-azul.png"/>
                                <div className="text">
                                    <p>Conta Azul</p>
                                    <p className="description">Faça vendas através do aplicativo, registre no conta azul</p>
                                    <a href={this.state.urlContaAzul}>Conectar</a>
                                </div>
                            </NavLink>
                        </li>



                        { /* <li>
                        <img alt="integração conta azul" src="https://junkee.com/wp-content/uploads/2019/03/slack.jpg"/>
                            <div className="text">
                                <p>Slack</p>
                                <p className="description">Receba notificações direto nos grupos da sua empresa no Slack</p>
                                <a href="https://api.contaazul.com/auth/authorize?redirect_uri=https://back-dashboard.laivon.com/contaazul/oauth-integration&client_id=puQp2imeJbP8xVLayPPjVSRHH4qKt8RM&scope=sales&state=46234237189j28323g263gh23j23g">Conectar</a>
                            </div>
                        </li>
                        <li>
                            <img alt="integração conta azul" src="https://sendgrid.com/wp-content/themes/sgdotcom/pages/resource/brand/2016/SendGrid-Logomark.png"/>
                            <div className="text">
                                <p>SendGrid</p>
                                <p className="description">Integrando ao SendGrid é criei regras para disparar e-mails automatizados</p>
                                <a href="https://api.contaazul.com/auth/authorize?redirect_uri=https://back-dashboard.laivon.com/contaazul/oauth-integration&client_id=puQp2imeJbP8xVLayPPjVSRHH4qKt8RM&scope=sales&state=46234237189j28323g263gh23j23g">Conectar</a>
                            </div>
                            </li>*/}
                    </ul>
                </div>
            </div>
            ); 
    }
}
    
const mapStateToProps = state => ({
    
});

const mapDispatchToProps = dispatch => {
    return {
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationsPage);