import ls from 'local-storage'
import checkSession from '../utils/session'
import {toastr} from 'react-redux-toastr'
const API_URL = process.env.API_URL;

function getHeaders(){
    const token = ls.get('token',null);
    const headers = new Headers();
    headers.append("authorization", token); 
    headers.append("Content-Type", "application/json"); 
    return headers;
}

export default class ReportApi {
    
    static async load(data) {

        const params = { 
            method: 'POST',
            headers: getHeaders(),
            cache: 'no-cache',
            body: JSON.stringify(data)
        };
        
        const url = API_URL + "/reports";
        
        let obj = await fetch(url,params)
        .then(response => response.json())
        .then(json => {
            checkSession(json);
            console.log("reposta", json)
            return json.data;
        })
        .catch(function(error) {
            console.log(error); 
            return []
        });

        console.log("Retornando", obj, data)
        return obj
    }

    
    static async list(data) {
        
        const params = { 
            method: 'POST',
            headers: getHeaders(),
            cache: 'no-cache',
            body: JSON.stringify(data)
        };
        
        const url = API_URL + "/reports/list";
        
        return fetch(url,params)
        .then(response => response.json())
        .then(json => {
            checkSession(json);
            console.log("reposta", json)
            return json.data;
        })
        .catch(function(error) {
            console.log(error); 
            return []
        });

    }
    
    static add(data) {
        const params = { 
            method: 'POST',
            headers: getHeaders(),
            cache: 'no-cache',
            body: JSON.stringify(data)
        };
        
        const url = API_URL + "/reports/add";
        
        return fetch(url,params)
        .then(function(response) {
            response.json().then(function(json) {
                if(parseInt(json.status) === 201){
                    toastr.success('Relatório', json.msg);
                }else{
                    toastr.error('Relatório', json.msg);
                }
                checkSession(json)
                return json
            });
        })
        .catch(function(error) {
            console.log(error); 
            return null
        });
    }
    
    static loadListTemplate(data){
        
        const params = { 
            method: 'POST',
            headers: getHeaders(),
            cache: 'no-cache',
            body: null
        };
        
        const url = API_URL + "/reports";
        
        fetch(url,params)
        .then(function(response) {
            console.log(response);
            response.json().then(function(data) {
                checkSession(data)
            });
        })
        .catch(function(error) {
            console.log(error); 
        });
    }
    
    static download(link){
        
        var params = { 
            method: 'POST',
            headers: getHeaders(),
            cache: 'no-cache',
            body: JSON.stringify(link)
        };
        
        const url = API_URL + "/reports/download";
        
        fetch(url,params)
        .then(function(response) {
            response.json().then(function(data) {
                checkSession(data)
            });
        })
        .catch(function(error) {
            console.log(error); 
        });
    }
    
}