export default function devices(state = [],action){
    switch (action.type) {
        case 'LOAD':
            return {data:action.data};

        case 'ADD':
            return {add:action.data};
            
        default:
            return state;
    }

}