import React, {Component} from 'react';
import ComponentInput from './component-input';
const API_URL = process.env.API_URL;

export default class CadastreForm extends Component{
    
    constructor() {
        super();
        this.state = {
            name:'',
            business:'',
            phone:'',
            email:'',
            password:''
        };
        this.sendForm = this.sendForm.bind(this);
    }
    
    sendForm(event){
        event.preventDefault();
        var headers = new Headers();
        headers.append('Content-Type', 'application/json'); 

        let json = {
            name: this.state.name,
            business: this.state.business,
            email: this.state.email,
            password: this.state.password,
            phone: this.state.phone
        }

        var params = { 
                method: 'POST',
                headers: headers,
                cache: 'no-cache',
                body: JSON.stringify(json)
            };
        
        const url = API_URL + "/cadastre";
        
        let self = this;
        fetch(url,params)
        .then(function(response) {
            response.json().then(function(data) {

                if(data.status === 201){
                    self.props.history.push("/login");
                }else{
                    console.log(data.msg)
                }
                
            });
        })
        .catch(function(error) {
            console.log(error); 
        });
    }
    
    onChange(field, event) {
        this.setState({[field]:event.target.value});
    }
    
    render(){
        return (
            <div className='pg-full cadastre'>
                <form onSubmit={this.sendForm} method="post">
                    <ComponentInput legend='Nome completo' placeholder='Nome e sobrenome' type='text' value={this.state.name} onChange={this.onChange.bind(this,'name')}/>
                    <ComponentInput legend='Empresa' placeholder='Sua empresa' type='text' value={this.state.business} onChange={this.onChange.bind(this,'business')}/>
                    <ComponentInput legend='Telefone' placeholder='(00) 00000-0000' type='text' value={this.state.phone} onChange={this.onChange.bind(this,'phone')}/>
                    <ComponentInput legend='E-mail coporativo' placeholder='Seu e-mail' type='email' value={this.state.email} onChange={this.onChange.bind(this,'email')}/>
                    <ComponentInput legend='Senha' placeholder='Senha maior que 6 digitos' type='password' value={this.state.password} onChange={this.onChange.bind(this,'password')}/>
                    <input type="submit" value="Criar Conta"/>    
                </form>
            </div>
            ); 
        }
    }