/* eslint-disable jsx-a11y/anchor-is-valid */

import React, {useEffect, useState} from 'react';
import {mdiBackburger, mdiDeveloperBoard, mdiFileChart, mdiExitToApp , mdiNfcTap , mdiAccountCircleOutline  } from '@mdi/js'
import Icon from '@mdi/react'

import { NavLink} from 'react-router-dom'
import ls from 'local-storage'
import logo from '../asserts/logo/logo.svg'
import Select from 'react-select'
import ClientApi from '../controllers/clientApi'

function NumberList(data) {
    
    const rows = data.data
    
    const listItems = rows.map((item, index) =>
    (item.access) ? (
        <li key={index}>
        <NavLink to={item.url} activeClassName="on"> 
        <Icon className='icon' path={item.icon}/>
        <p>{item.label}</p>
        </NavLink>
        </li>
        ) : null
        );
        return <ul>{listItems}</ul>;
    }
    
    export default function Menu() {

        const [clients, setClients] = useState([])
        
        const [clientSelected] = useState(false);
        
        let isCompact = true

        try {
            const options = ls.get("options", null)
            isCompact = (options !== null && options !== undefined) ? options.compactMode : true            
        } catch (error) { }

        const [compactMode, setCompactMode] = useState(isCompact);
        
        const [selectClientShow, setSelectClientShow] = useState(false);
        
        const [links] = useState([
            { url:"/environments", icon: mdiDeveloperBoard, label: "Ambientes", access: true},
            { url:"/reports", icon: mdiFileChart, label: "Relatórios", access : true },
            { url:"/integrations", icon: mdiNfcTap  , label: "Integrações" , access: false},
            { url:"/logout", icon: mdiExitToApp , label: "Sair", access: true },
        ]);

        useEffect(() => {
            if(compactMode !== undefined && compactMode !== null){
                ls.set("options", {compactMode:compactMode})
            }
        },[compactMode])
        
        useEffect(() => {
            ClientApi.listClientByAccount().then(nClients => {
                let tempClient = []
                
                for (let i = 0; i < nClients.length; i++) {
                    tempClient.push({
                        label: nClients[i].name,
                        value: nClients[i]._id
                    })
                }
                
                setClients(tempClient)
            })
            
        },[])
        
        
        const handleChange = (field) => {
            
            let user = ls.get('user',null);
            
            const client = {
                id : field.value,
                name : field.label
            }        
            
            user.client = client
            
            ls.set('user',user);
            
            window.location.reload();
        }
        
        const clientSelect = (selectClientShow) ? (
            <Select className="select select-client" 
            value={clientSelected} 
            onChange={handleChange} 
            options={clients} 
            placeholder="Cliente"/>
            ) : null
            
            const laivon = (
                <figure className='logo' >
                <img src={logo} alt='logo'/>
                </figure>   
                )
                
                return (
                    <section id="Menu" className={(compactMode) ? "compact": ""}>
                    <div className='subHeader'>
                    <Icon className='btExpand' path={mdiBackburger} onClick={() => {setCompactMode(!compactMode)}}/>
                    {
                        (clients.length > 0) ? (
                            <Icon className='btAccount' path={mdiAccountCircleOutline} onClick={() => {setSelectClientShow(!selectClientShow)}}/>
                            ) : null
                        }
                        </div>
                        {clientSelect}
                        
                        {laivon}
                        <NumberList data={links}/>   
                        </section>
                        )
                    }
                    