/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import GridUsersState from "../../components/environment/grid-users-state";
import Progress from '../../components/component-progress';
import EnvironmentApi from '../../controllers/environmentApi'
import CountUp from 'react-countup';

import {
    useParams
  } from "react-router-dom";
 
export default function PageEnvironments() {

    const params = useParams();

    const [environment, setEnvironment] = useState({
        token: params.token,
        alias: "Carregando...",
        logo: "",
        name: "Carregando..."
    })

    const [resume, setResume] = useState({
        schedules: { total: 0, cancel: 0, schedule: 0, inField: 0, done: 0, progress: 0 }, 
        users: { on: 0, off: 0 },
        activities: {total:0, done: 0, list:[]}
    })

    const [progress, setProgress] = useState(0)
    
    const [data, setData] = useState([]);

    const refresh = async () => {
        const json = {
            "token": environment.token,
            "date":  new Date(),
        }

        let nEnvironments = await EnvironmentApi.loadEnvironment(json);

        if(nEnvironments !== null){

        setEnvironment({
            token:environment.token,
            alias:nEnvironments.alias,
            name: nEnvironments.name,
            logo: nEnvironments.theme.logo
        })

        setData(nEnvironments.data)

        setResume(nEnvironments.resume)

        
    }
    };
	
    useEffect(() => {
        refresh()
    },[])

    useEffect(() => {
        const progress = (parseInt((resume.schedules.done / resume.schedules.total) * 100  ));
        setProgress(progress)
    },[resume])

    	
    useEffect(() => {
        console.log("Data alterada", data)
    }, [data])

    
    return (
        <div id="environmentsView" className="pg">

        <div className='content'>
        
            <header className="panel">
                <figure>
                <img src={environment.logo} alt="logo" className="logo"/>
                </figure>
                <div className="text">
                <h1>{environment.alias}</h1>
                <div className="actions">
                <a href={"https://" + environment.name + ".umov.me"} rel="noopener noreferrer" target="_blank" >Abrir ambiente</a>
                </div>
                </div>
            </header>
        
            <section className="dashboard-cards">

            <div className="dahsboard-card synchronism">
                <legend className="header-label">Status dos usuários</legend>
                <div className="content">
                    <div className="side on">
                    <h4>
                        <CountUp start={0} end={resume.users.on} duration={2}></CountUp>
                    </h4>
                    <label>online</label>
                    </div>
                    <div className="side off">
                    <h4>
                        <CountUp start={0} end={resume.users.off} duration={2}></CountUp>    
                    </h4>
                    <label>offline</label>
                    </div>
                </div>
            </div>

                <div className="dahsboard-card visits">
                    <legend className="header-label">Status das Visitas</legend>
                    <div className="content">

                    <div className='box-progress'>
                    <h4>
                        <CountUp start={0} end={progress} duration={2} suffix="%"></CountUp>
                    </h4>
                        <Progress value={progress} total={100} options={{
                            display: false,
                            duration:2
                        }}/>
                    </div>
                    <ul>
                        <li><p>Total:</p><span>{resume.schedules.total}</span></li>
                        <li><p>Canceladas:</p><span>{resume.schedules.cancel}</span></li>
                        <li><p>Concluídas:</p><span>{resume.schedules.done}</span></li>
                        <li><p>Em Campo:</p><span>{resume.schedules.inField}</span></li>
                        { resume.schedules.schedule > 0 ? <li  className='error'><p>Não sincronizadas:</p><span>{resume.schedules.schedule}</span></li>
                                :<li><p>Não sincronizadas:</p><span>{resume.schedules.schedule}</span></li>
                        }
                    </ul>
                    </div>
                </div>

            </section>

            <GridUsersState data={data}/>

         </div>
        </div>
    ); 
}
    
