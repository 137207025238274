import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import application from './application'
import client from './client'
import environment from './environment'
import report from './report'
import account from './account'
import {reducer as toastrReducer} from 'react-redux-toastr'
import activity from './activity'

export default (history) => combineReducers({
  router: connectRouter(history),
  application,
  environment,
  report,
  client,
  account,
  activity,
  toastr: toastrReducer
})