
import React, {useEffect, useState} from 'react'
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import Progress from '../../components/component-progress';
import { mdiArrowTopRight } from '@mdi/js';
import Icon from '@mdi/react'


export default function EnviromentCard({data}) {

    const [environment, setEnvironment] = useState({})
    
    useEffect(() => {

        let obj = {
            totalUsers:0,
            connectedUsers:0,
            totalSchedules:0,
            doneSchedules:0,
            name:"laivon",
            alias:"Ambiente",
            logo:"https://laivon.com/assets/Laivon-Apps.png",
            isDone:false,
            url:"https://umov.me/CenterWeb/",
            token:""
        }

        try { obj.totalUsers = data.users.on + data.users.off; } catch (error) {  }
        try { obj.totalSchedules = data.schedules.total } catch (error) { }

        try { obj.doneSchedules = data.schedules.done } catch (error) { }
        try { obj.connectedUsers =  data.users.on } catch (error) { }
        
        try { obj.alias = data.alias } catch (error) {}
        try { obj.logo = data.theme.logo } catch (error) {}
        try { obj.name = data.name } catch (error) {}
        try { obj.token = data.token } catch(error) {}

        obj.url = "https://"+ data.name +".umov.me/CenterWeb/";

        obj.isDone = (obj.totalSchedules === obj.doneSchedules && obj.totalSchedules > 0);

        setEnvironment(obj)

    },[data])

    return (
        <li  className={"environment-card " + (environment.isDone ? 'done' : '')}>

            <NavLink to={"/environments/" + environment.token} >
                <p className="name">{environment.alias}</p>
                <figure>
                    <img alt="cover" src={environment.logo}/> 
                </figure>
                <div className="user-status">
                    <Progress value={environment.doneSchedules} total={environment.totalSchedules} display={true}/>
                </div>
                <div className="analytics">
                    <ul className="counters">
                        <li>
                            <p>{environment.doneSchedules}<span>/</span>{environment.totalSchedules}</p>
                            <label>Tarefas</label>
                        </li>
                        <li>
                            <p>{environment.connectedUsers}<span>/</span>{environment.totalUsers}</p>
                            <label>Equipe</label>
                        </li>
                    </ul>
                </div>
            </NavLink> 
        
            <a href={environment.url} rel="noopener noreferrer" target="_blank" className="link" >
                <Icon path={mdiArrowTopRight} size={1} /> 
            </a>

        </li>
    ); 
}
    
