/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import ls from 'local-storage'
import EnvironmentApi from '../../controllers/environmentApi'
import Select from 'react-select'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import {toastr} from 'react-redux-toastr'
import ReportApi from '../../controllers/reportApi';

export default function FormReport({openForm, save}) {
    
    const [environmentSelected, setEnvironmentSelected] = useState(null)
    const [reportSelected, setReportSelected] = useState(null)
    const [modelEnvironment, setModelEnvironment] = useState([])
    const [modelReports, setModelReports] = useState([])
    
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [clientId, setClientId] = useState(null)
    
    const refresh = async () => {
        const user = ls.get("user", null);
        setClientId(user.client.id)
        let nEnvironments = await EnvironmentApi.loadListEnvironments({"clientId":user.client.id});
        
        const temp = []
        
        for (let index = 0; index < nEnvironments.length; index++) {
            const row = nEnvironments[index];
            temp.push({
                value: row.token,
                label: row.alias
            })
        }
        
        setModelEnvironment(temp)
    };
    
    const handleChangeEnvironment = async (selected) => {
        
        setEnvironmentSelected(selected)
        
        const nReports = await ReportApi.list({token:selected.value});
        
        setModelReports(nReports)
        
    }
    
    const handleChangeReport = (selected) => {
        setReportSelected(selected)
    }
    
    const sendForm = (event) => {

        event.preventDefault();
        console.log(event)
        
        if(environmentSelected === null){
            toastr.warning('Atenção',"Informe o ambiente");
            return;
        }
        
        if(reportSelected === null){
            toastr.warning('Atenção',"Informe o relatório");
            return;
        }
        
        if(startDate === "" || endDate === "" ){
            toastr.warning('Atenção',"Informe uma data válida");
            return;
        }
        
        const json = {
            "startDate":startDate,
            "endDate":endDate,
            "clientId":clientId,
            "environment":{
                "token":environmentSelected.value,
                "alias":environmentSelected.label
            },
            "report":reportSelected.value
        }
        
        save(json)
    }
    
    useEffect(() => {
        refresh()
    }, [])
    
    return (
        <div className={"form-report " + (openForm ? 'on' : '')}>
        <h3>Novo Relatório</h3>
        <form onSubmit={(onSubmit) => sendForm(onSubmit)} method="post">
        <Select className="select" 
        value={environmentSelected} 
        onChange={handleChangeEnvironment} 
        options={modelEnvironment} 
        placeholder="Ambiente"/>
        
        <Select className="select" 
        value={reportSelected} 
        onChange={handleChangeReport} 
        options={modelReports} 
        placeholder="Relatório"/>
        
        <DatePicker
        selected={startDate}
        placeholderText="Data Inicial"
        selectsStart
        startDate={startDate}
        endDate={endDate}
        onChange={setStartDate}/>
        
        <DatePicker
        selected={endDate}
        placeholderText="Data Final"
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        onChange={setEndDate}
        minDate={startDate}/>
        
        <input type="submit" value="Gerar Relatório"/>
        </form>
        </div>
        ); 
    }
    
    