/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import ls from 'local-storage'

import EnviromentCard from '../../components/environment/environment-card'
import EnvironmentApi from '../../controllers/environmentApi'
import ComponentLoadingPage from '../../components/ComponentLoadingPage'
import Header from './Components/Header'

export default function PageEnvironments() {

    const [environments, setEnvironments] = useState([])
    const [loading, setLoading] = useState(true)

    const refresh = async () => {
        const user = ls.get("user", null);
        let nEnvironments = await EnvironmentApi.loadListEnvironments({"clientId":user.client.id});
        setEnvironments(nEnvironments)
        setLoading(false)
    };
	
    useEffect(() => {
        refresh()
		}, [])
    
    return (
        <div id="environments" className="pg">
        {
            (loading) ? (
                <ComponentLoadingPage />
            ) : (
                <div className='content'>
                    <Header data={environments}/>
                    <ul className='environments-list'>
                        {
                            environments.map((enviroment)=>
                                <EnviromentCard key={enviroment.name} data={enviroment}/>
                            )
                        }
                    </ul>
                </div>
            )
        }
        
        </div>
    ); 
}
    
