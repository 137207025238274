import ReactTable from "react-table";

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'

export default function GridUSersState({data}) {
    
    const [model, setModel] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [dateLimit, setDateLimit] = useState("");
    console.log("Danta dentro do componente",data)

    const refresh = () => {
        let nModel = [];

        for (let i = 0; i < data.length; i++) {
            let item = data[i];
            let total = item.states.done + item.states.inField + item.states.schedule;
            let done = item.states.done;
            
            let progressTask = ((done * 100) / total).toFixed(0);
            progressTask = (isNaN(progressTask)) ? 0 : progressTask
            
            let options = { hour: '2-digit', minute: '2-digit', second: '2-digit' };
            
            let fisrtAction = ""
            
            console.log("fisrtAction", item.fisrtAction)

            if(item.fisrtAction !== undefined && item.fisrtAction !== null) {
                fisrtAction = new Date(item.fisrtAction)
                fisrtAction.setHours(fisrtAction.getHours() + 3)
                fisrtAction = fisrtAction.toLocaleDateString('pt-BR', options);
                fisrtAction = String(fisrtAction).substr(11,8);
            }
            
            let lastSynchronism;
            
            if(item.lastSynchronism !== null){
                lastSynchronism = new Date(item.lastSynchronism).toISOString().replace("T", " ").replace(".000Z", "");
            }

            
            nModel.push({
                "name":item.name,
                "fisrtAction": fisrtAction,
                "lastSynchronism":lastSynchronism,
                "progressTask":progressTask + "%",
                "tasksDone": done,
                "tasksTotal": total
            })
        }
        
        setModel(nModel);

        let nPageSize = (model.length > 0) ? model.length : 25;
        let nDateLimit = new Date(new Date().toISOString().substring(0, 10) + " 00:00:00");
        
        setPageSize(nPageSize)
        setDateLimit(nDateLimit)

    };
    
    useEffect(() => {
        refresh()
    }, [data])
    
    return (
        <section className="grid-state-users">
        <h1>Promotores</h1>
        
        <ReactTable 
        data={model}
        showPagination={true}
        columns={[{
            Header: 'Promotor',
            columns: [{
                Header: 'Nome',
                id: 'name',
                accessor: d => d.name
            }, {
                Header: '1º ação do dia',
                id: 'fisrtAction',
                accessor: d => d.fisrtAction
            },
            {
                Header: 'Visitas',
                id: 'tasksTotal',
                accessor: d => d.tasksTotal
            },{
                Header: 'Feito',
                id: 'tasksDone',
                accessor: d => d.tasksDone
            },
            {
                Header: 'Último sincronismo',
                id: 'lastSynchronism',
                accessor: d => d.lastSynchronism,
                Cell: row => (
                    <span>
                    <span style={{
                        color: (new Date(row.value) < dateLimit) || (row.value === undefined) ? '#ff2e00'
                        : '#57d500',
                        transition: 'all .3s ease'
                    }}>
                    &#x25cf;
                    </span> {
                        row.value === 'relationship' ? 'In a relationship'
                        : row.value === 'complicated' ? `It's complicated`
                        : row.value
                    }
                    </span>
                    )
                }
            ]
        }]}
        defaultPageSize={pageSize}
        className="grid-theme"/>
        </section>
        ); 
}    