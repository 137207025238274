import React, {Component} from 'react'
import { connect } from 'react-redux'
import clientApi from '../../controllers/clientApi'
import { Input , Button} from 'semantic-ui-react'
import {toastr} from 'react-redux-toastr'
class ClientIOPage extends Component{
    
    constructor(props) {
        super(props);

        this.state = {
            name:"",
            url:"",
            value:"",
            colorPrimary:"#000000",
            colorSecundary:"#FFFFFF",
            data: null,
            id: props.match.params.id
        };
        
        this.sendForm = this.sendForm.bind(this);
    }
    
    componentDidMount(){
        if(this.state.id !== undefined){
            this.props.load(this.state.id);
        }
    }


    componentWillReceiveProps(newprops){
        if(newprops.data !== undefined){
            this.setState({
                name:"",
                value:""
            });
        }

        if(newprops.client !== undefined){

            this.setState({
                name:newprops.client.name,
                value:newprops.client.value
            });
        }
    }
    
    sendForm(event){
        event.preventDefault()
        let value = parseFloat(this.state.value);
        if(this.state.name === "" || value < 1 || isNaN(value)){
            toastr.warning('Inválido', 'Preencha os campos corretamente');
        }else{
            let json = {
                name: this.state.name,
                value: this.state.value,
                theme: {
                    url:this.state.url,
                    colorPrimary:this.state.colorPrimary,
                    colorSecundary:this.state.colorSecundary
                }
            }
            
            if(this.state.id === undefined){
                this.props.add(json);
            } else {
                json.id = this.state.id
                this.props.edit(json);
            }
        }
    }
    
    onChange(field, event) {
        this.setState({[field]:event.target.value});
    }
    
    setColor(field,event) {
        this.setState({[field]:event.hex});
    }
    
    render(){
        
        return (
            <div id="ClientIO" className="pg">
            <div className='content'>
            <form onSubmit={this.sendForm} method="post">
            <h1>Cadastro de Clientes</h1>
            
            <Input placeholder="Nome do Cliente" 
            value={this.state.name} 
            onChange={this.onChange.bind(this,'name')} 
            type="text" />
            
            <Input
            label='R$'
            placeholder='Valor do Contrato'
            value={this.state.value} 
            type="number"
            onChange={this.onChange.bind(this,'value')}
            />
            
            <Button>Cadastrar</Button>
            
            </form>
            </div>
            </div>
            ); 
        }
    }
    
    const mapStateToProps = state => ({
        data:state.client.new,
        client:state.client.client
    });
    
    const mapDispatchToProps = dispatch => {
        return {
            add:(data) => {
                dispatch(clientApi.add(data))
            },
            edit:(data) => {
                dispatch(clientApi.edit(data))
            },
            load:(id) => {
                dispatch(clientApi.load(id))
            }
        }
    }
    
    export default connect(mapStateToProps, mapDispatchToProps)(ClientIOPage);