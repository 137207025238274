/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import ls from 'local-storage'
import { parseISO, format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import "react-datepicker/dist/react-datepicker.css"
import excelIcon from '../../asserts/icons/excel.png'
const API_URL = process.env.API_URL;

export default function ReportList({data}) {

    const [reports, setReports] = useState([])

    const processData = (data) => {
        let list = []

        for (let i = 0; i < data.length; i++) {
            const row = data[i];
            let init = row.period.init
            let end = row.period.end
            let period = ""
            let createdAt = ""
            let now = new Date()
            now = parseISO(now.toISOString())

            let parsedDateInit = parseISO(init);
            let parsedDateEnd = parseISO(end);
            let znDate = zonedTimeToUtc(parsedDateInit, 'America/Sao_Paulo');
            let znDate2 = zonedTimeToUtc(parsedDateEnd, 'America/Sao_Paulo');
            
            let formattedDate = format(
                znDate, 
                "dd'/'MM'"
            );

            let formattedDate2 = format(
                znDate2, 
                "dd'/'MM'"
            );

            createdAt = String(row.createdAt).substring(0,10)
            createdAt = (createdAt === "undefined" || createdAt === "") ? "" : createdAt
            period = (formattedDate === formattedDate2) ? "Do dia " + formattedDate : "De " + formattedDate + " a " + formattedDate2 

            list.push({
                "period": period,
                "environment": row.environment.alias,
                "state":row.state,
                "name": row.name,
                "link": row.link,
                'logo': row.environment.theme.logo
            })
        }

        return list;
    }

    useEffect(() => {
        setReports(processData(data))
    }, [data])

    return (
        <div id="ReportList">
            <ul>
            {
                reports.map((report)=>
                    <li>

                        {
                            (report.state === "Pronto") ? (
                                <a rel="noopener noreferrer" target="_blank" href={API_URL + report.link}  title={'Download ' + report.name}>  
                                 <figure>
                                    <img src={excelIcon} alt={report.environment}/>
                                </figure>

                                <div className='text'>
                                    <p className='name'>{report.environment}</p>
                                    <label>{report.period}</label>
                                    <label>{report.name}</label>
                                    <p className='get'>Download </p>
                                </div>

                                </a>
                            ) : (<div className='contentli'>

                                <figure>
                                    <img src={excelIcon} alt={report.environment}/>
                                </figure>

                                <div className='text'>
                                    <p className='name'>{report.environment}</p>
                                    <label>{report.period}</label>
                                    <label>{report.name}</label>
                                    <label>{report.state}</label>
                                </div>

                            </div>)
                        }
                        
                    </li>
                )
            }
            </ul>
        </div>
    ); 
}
    

