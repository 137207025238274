import React, { Component } from 'react'
import PageNotFound from './pages/404.js'

import ClientPage from "./pages/client/index"
import ClientIO from "./pages/client/io"

import EnvironmentPage from "./pages/environments/index"
import EnvironmentIO from "./pages/environments/io"
import EnvironmentView from "./pages/environments/view"

import ReportPage from "./pages/reports/index"
import ReportIO from "./pages/reports/io"

import UserIoPage from "./pages/users/user-io"
import ManagerIoPage from "./pages/users/manager-io"

import IntegrationPage from "./pages/integrations/index"
import ContaAzulPage from "./pages/integrations/contaazul/index"

import Menu from './components/sidemenu'
import {Route, Switch} from "react-router-dom"
import { connect } from 'react-redux'
import applicationApi from './controllers/applicationApi'

class App extends Component {
    
    constructor(props){
        super(props);
        this.state = { isOpenbk : false };
    }
    
    componentWillReceiveProps(newprops){
        console.log("app", newprops);
        
        if(newprops.application.openNew !== undefined){
            
                this.setState({
                    isOpenbk : newprops.application.openNew
                })
                
        }
        
        if(newprops.application.openSearch !== undefined){
                this.setState({
                    isOpenbk : newprops.application.openSearch
                })
        }
    }
    
    render() {
        return (
            <div className="app">
            <Menu/>
            <div className='main'>
            <Switch>
                <Route exact path="/" component={EnvironmentPage}  />
                <Route exact path="/environments" component={EnvironmentPage} />
                <Route exact path="/environments/new" component={EnvironmentIO} />
                <Route exact path="/environments/:token" component={EnvironmentView} />
                <Route exact path="/reports" component={ReportPage} />
                <Route exact path="/reports/new" component={ReportIO} />
                <Route exact path="/clients" component={ClientPage} />
                <Route exact path="/clients/io" component={ClientIO} />
                <Route exact path="/clients/io/:id" component={ClientIO} />
                <Route exact path="/users/new" component={UserIoPage} />
                <Route exact path="/account/user" component={ManagerIoPage} />
                <Route exact path="/integrations" component={IntegrationPage} />
                <Route exact path="/integrations/contaazul" component={ContaAzulPage} />
                <Route path="*" component={PageNotFound} />
            </Switch>
            </div>
            <div onClick={this.props.closePopup} className={"bk-main " + (this.state.isOpenbk ? 'on' : '')} ></div>
            </div>
            );
        }
    }
    
    
    const mapStateToProps = state => ({ 
        application : state.application
    });
    
    const mapDispatchToProps = dispatch => {
        return {
            closePopup:() => {
                dispatch(applicationApi.openNew(false));
            }
        }
    }
    
    export default connect(mapStateToProps, mapDispatchToProps)(App);