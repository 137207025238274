import React, {Component} from 'react'
import { connect } from 'react-redux'
import "react-datepicker/dist/react-datepicker.css";
import ls from 'local-storage'
import reportApi from '../../controllers/reportApi'
import environmentApi from '../../controllers/environmentApi';
import { Checkbox, Input, Button } from 'semantic-ui-react'
//import InputColor from 'react-input-color'
import Select from 'react-select'

class ReportsPage extends Component{
    
    constructor(props) {
        super(props);
        
        this.state = {
            activities:[],
            environments:[],
            selectedEnvironment:null,
            name:"",
            listActivities:[],
            onlyExecution:true,
            splitActivity:false
        }
        
        this.sendForm = this.sendForm.bind(this)
    }
    
    componentDidMount(){
        let user = ls.get('user',null);

        if(user.businessId !== undefined){
            this.props.loadEnvironments({"clientId":user.businessId, "simple":true});
            this.props.loadReports({"clientId":user.businessId, "simple":true});
        }
    }
    
    componentWillReceiveProps(nextProps){
        
        if(nextProps.data !== undefined){
            this.setState({
                data:nextProps.data
            });
        }
        
        if(nextProps.environments !== undefined){
            let data = nextProps.environments;
            let list = []
            
            for (let i = 0; i < data.length; i++) {
                let environment = data[i];
                let obj = {value: environment.token , label: environment.alias};
                list.push(obj)
            }
            
            this.setState({
                environments:list
            })
        }
        
    }
    
    handleChangeEnvironment = (modelSelected) => { this.setState({ modelEnvironment:modelSelected }); }
    handleChangeReports = (modelSelected) => { this.setState({ modelReports:modelSelected }); }
    
    sendForm(event){
        event.preventDefault();
        var headers = new Headers();
        headers.append('Content-Type', 'application/json'); 
        headers.append('Access-Control-Allow-Credentials', true);
        
        if(this.state.modelEnvironment !== null && this.state.modelReports !== null){
            let json = {
                "startDate":this.state.startDate,
                "endDate":this.state.endDate,
                "environment":{
                    "token":this.state.modelEnvironment.value,
                    "alias":this.state.modelEnvironment.label
                },
                "report":this.state.modelReports.value
            }
            
            this.props.add(json);
        }
    }

    onChange(field, event) {
        this.setState({[field]:event.target.value});
    }

    handleChange = (field,selected) => {
        this.setState({[field]:selected});
    }
    
    render(){
        
        /*
        let list = this.state.data.map((report,i) => {
            let link = <a href={report.link} rel="noopener noreferrer" target="_blank" className="link"> Donwload </a>;
            let state = <p className={report.state}>{report.state}</p>;
            let stateObj = (report.state === "done") ? link : state;
            
            return <li key={i}> 
            <p>{report.model}</p>
            <p>{report.environment}</p>
            <p>{report.date}</p>
            {stateObj}
            </li>
            <ul className="list-reports">{list}</ul>

        });*/
        
        
        return (
            <div id="report" className="pg" >
            <div className='content'>
            <div className="panel">
            <h1>Relatórios</h1>
            </div>
            <div className="form-report">
            <form onSubmit={this.sendForm} method="post">

            <Input placeholder="Nome do Modelo"
             value={this.state.name} 
             onChange={this.onChange.bind(this,'name')}/>

            <Checkbox label='Somente dados de Execução' 
             value={this.state.onlyExecution} 
             onChange={this.onChange.bind(this,'onlyExecution')}/>

            <Checkbox label='Gerar relatório para cada Atividades' 
             value={this.state.splitActivity} 
             onChange={this.onChange.bind(this,'splitActivity')}/>

            <Select className="select-client" 
            value={this.state.selectedEnvironment} 
            onChange={this.handleChange.bind(this,'selectedEnvironment')} 
            options={this.state.clients} 
            placeholder="Cliente"/>

            <Button>Gerar Template</Button>
            
            </form>
            </div>
            </div>
            </div>
            ); 
        }
    }
    
    const mapStateToProps = state => ({
        data:state.report.data,
        environments:state.environment.environments
    });
    
    const mapDispatchToProps = dispatch => {
        return {
            load:() => { /// Listagem de reports
                dispatch(reportApi.load());
            },
            loadEnvironments:(data) => { /// carrega ambientes.
                dispatch(environmentApi.loadListEnvironments(data));
            },
            loadReports:(data) => { /// Listagem de templates.
                dispatch(reportApi.loadListTemplate(data));
            },
            add:(data) => { /// adicionar agendamento.
                dispatch(reportApi.add(data));
            } 
        }
    }
    
    export default connect(mapStateToProps, mapDispatchToProps)(ReportsPage);        