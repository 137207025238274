import ls from 'local-storage'
import { add } from '../actions/account'
import checkSession from '../utils/session'
import {toastr} from 'react-redux-toastr'

let token = ls.get('token',null);

var headers = new Headers();
headers.append("authorization", token); 
headers.append("Content-Type", "application/json"); 
const API_URL = process.env.API_URL;
export default class AccountApi {
    
    // adicionar
    static add(data){
        return dispatch => {
          
            var params = { 
                method: 'POST',
                headers: headers,
                cache: 'no-cache',
                body: JSON.stringify(data)
            };
            
            const url = API_URL + "/user/add";
            
            fetch(url,params)
            .then(response => response.json())
            .then(json => {

                if(parseInt(json.status) === 201){
                    toastr.success('Novo usuário', json.msg);
                }else{
                    toastr.error('Novo usuário', json.msg);
                }
                
                checkSession(json);
                dispatch(add(json));
            })
            .catch(function(error) {
                console.log(error); 
            });
        }
    }

}