import React, {Component} from 'react';

export default class ComponentInput extends Component{
    render(){
        return (
        <fieldset className={"component-input " + this.props.className}>
            <legend>{this.props.legend}</legend>
            <input {...this.props} />
            <span className="bar">{this.props.helper}</span>
        </fieldset>

        ); 
    }
}