import React, {Component} from 'react';
import ComponentInput from './component-input';
//import { Link } from 'react-router-dom';

import logo from '../asserts/logo/logo.svg';
import ls from 'local-storage';
const API_URL = process.env.API_URL;

class LoginForm extends Component{

    constructor() {
        super();
        this.state = {
            password:'', 
            email:''
        };
        this.sendForm = this.sendForm.bind(this);
    }

    componentWillMount(){
        let token = ls.get('token',null);
        
        if(token !== null){ 
           this.props.history.push("/");
        }
    }

    sendForm(event){
        event.preventDefault();
        var headers = new Headers();
        headers.append('Content-Type', 'application/json'); 
        headers.append('Access-Control-Allow-Credentials', true);

        const params = { 
                method: 'POST',
                headers: headers,
                cache: 'no-cache',
                body: JSON.stringify({
                    email: this.state.email,
                    password: this.state.password
                })
            };
        
        const url = `${API_URL}/login`;
        
        fetch(url,params)
        .then(function(response) {
            response.json().then(function(data) {
                ls.set('token',data.token);
                ls.set('user',data.user);
                ls.set('options', {compactMode:true});
                window.location.reload(); 
            });
        })
        .catch(function(error) {
            console.log(error); 
        });
    }

    onChange(field, event) {
        this.setState({[field]:event.target.value});
    }

    render(){
        return (
        <div className='pg-full login'>
            <form onSubmit={this.sendForm} method="post">
                <img className='logo' alt='logo' src={logo}/>
                <ComponentInput legend='Email corporativo' placeholder='Email' type='email' value={this.state.email} onChange={this.onChange.bind(this,'email')}/>
                <ComponentInput legend='Senha' placeholder='Senha' type="password" value={this.state.password} onChange={this.onChange.bind(this,'password')}/>
                <input type='submit' value='Entrar'/>
              
                <div className='links'>
                  {/*  <Link to="/cadastre">Criar conta</Link>*/ }
                </div>
            </form>
        </div>
        ); 
    }
}

export default LoginForm;