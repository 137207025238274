import ls from 'local-storage'
import { sync } from '../../actions/contaazul'
import { toastr } from 'react-redux-toastr'
import checkSession from '../../utils/session'

let token = ls.get('token',null);

var headers = new Headers();
headers.append("authorization", token); 
headers.append("Content-Type", "application/json"); 
const API_URL = process.env.API_URL;

export default class ContaAzulApi {
    
    static synchronism(data){
        return dispatch => {
          
            var params = { 
                method: 'POST',
                headers: headers,
                cache: 'no-cache',
                body: JSON.stringify(data)
            };
            
            const url = API_URL + "/contaazul/sync";
            
            fetch(url,params)
            .then(response => response.json())
            .then(json => {

                console.log(json);
                
                if(parseInt(json.status) === 200){
                    toastr.success('Sincronizando', json.msg);
                }else{
                    toastr.error('Falha ao Sincronismo', json.msg);
                }
                
                checkSession(json);
                dispatch(sync(json));
            })
            .catch(function(error) {
                console.log(error); 
            });
        }
    }

}