import React, {Component} from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import { Input , Button} from 'semantic-ui-react'
import {toastr} from 'react-redux-toastr'
import ClientApi from '../../controllers/clientApi'
import AccountApi from '../../controllers/accountApi'


class UserIOPage extends Component{
    
    constructor(props) {
        super(props);
        
        this.state = {
            name:"",
            email:"",
            password:"",
            phone:"",
            clients:[],
            newUser:null,
            clientSelected: undefined,
        };
        
        this.sendForm = this.sendForm.bind(this);
    }
    
    componentDidMount(){
        this.props.loadClient({"complete":false});
    }
    
    componentWillReceiveProps(newprops){
        
        if(newprops.clients !== undefined){
            
            let clients = []
            
            for (let i = 0; i < newprops.clients.length; i++) {
                const client = newprops.clients[i];
                clients.push({"value":client._id, "label":client.name});
            }
            
            this.setState({clients:clients});
        }
        
        if(newprops.newUser !== undefined){
            this.setState({
                name:"",
                email:"",
                password:"",
                phone:"",
                clientSelected: null,
            });
        }
    }
    
    sendForm(event){
        event.preventDefault();
        
        if(this.state.email === "" || this.state.name === "" || 
        this.state.password === "" || this.state.clientSelected === undefined
        || this.state.clientSelected === null){
            toastr.warning('Inválido', 'Preencha os campos corretamente');
        }else{
            
            let client = null;
            try {
                if(this.state.clientSelected.value !== null || this.state.clientSelected.value === undefined){
                    client = this.state.clientSelected.value;
                }
            } catch (error) {}

            let json = {
                "name":this.state.name,
                "email":this.state.email,
                "phone":this.state.phone,
                "password":this.state.password,
                "clientId": client
            }
            
            this.props.add(json);
        }
        
    }
    
    onChange(field, event) {
        this.setState({[field]:event.target.value});
    }
    
    handleChange = (field,selected) => {
        this.setState({[field]:selected});
    }
    
    render(){
        
        return (
            <div id="UserIO" className="pg">
            <div className='content'>
            <form onSubmit={this.sendForm} method="post">
            <h1>Cadastro de Usuário</h1>
            
            <Input placeholder="Nome" 
            value={this.state.name} 
            onChange={this.onChange.bind(this,'name')} 
            type="text" />
            
            <Input placeholder="Email" 
            value={this.state.email} 
            onChange={this.onChange.bind(this,'email')} 
            type="email" />
            
            <Input placeholder="Telefone" 
            value={this.state.phone} 
            onChange={this.onChange.bind(this,'phone')} 
            type="phone" />
            
            <Input placeholder="Senha" 
            value={this.state.password} 
            onChange={this.onChange.bind(this,'password')} 
            type="password" />
            
            <Select className="select-client"
            value={this.state.clientSelected} 
            onChange={this.handleChange.bind(this,'clientSelected')} 
            options={this.state.clients} 
            placeholder="Cliente"/>
            
            <Button>Cadastrar</Button>
            </form>
            </div>
            </div>
            ); 
        }
    }
    
    const mapStateToProps = state => ({
        clients: state.client.list,
        newUser : state.account.new
    });
    
    const mapDispatchToProps = dispatch => {
        return {
            loadClient:(data) =>{
                dispatch(ClientApi.listClientByAccount(data))
            },
            add:(data) => {
                dispatch(AccountApi.add(data));
            }
        }
    }
    
    export default connect(mapStateToProps, mapDispatchToProps)(UserIOPage);