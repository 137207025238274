export default function application(state={}, action) {

    switch (action.type) {
        
        case 'LOAD_SIDEMENU':
            return action.account;

        case 'OPEN_CONFIG':
            return action.isOpen

        case 'OPEN_SEARCH':
            return {openSearch:action.openSearch}
        case 'OPEN_NEW':
            return {openNew:action.openNew}

        case 'OPEN_USER_MENU':
            return action.isOpen
            
        case 'CHANGE_CLIENT':
            return {clientSelected:action.clientSelected}

        default:
            return state;
    }
}
