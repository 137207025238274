import ls from 'local-storage';

export default function checkSession(data){

    if(data.error === "No token provided"){
        ls.remove('token');
        ls.remove('user');   
        window.location.reload();  
    }   

    if(data.error === "Token invalid"){
        ls.remove('token'); 
        ls.remove('user');    
        window.location.reload();
    }

}