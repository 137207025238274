import React, {Component} from 'react'
import { connect } from 'react-redux'
import ReactTable from "react-table"
import user from '../../utils/user'
import ClientApi from '../../controllers/clientApi'
class ClientPage extends Component{
    
    constructor(props) {
        super(props);
        
        this.state = {
            kpis:{
                users:{
                    total:0,
                    userLogin:0,
                    vMediaEnvironmentUsers:0
                },
                tasks:{
                    total:0,
                    vMediaTask:0,
                    vMediaEnvironmentTask:0
                },
                value:{
                    total:0,
                    vMediaUser:0,
                    vMediaEnvironment:0
                }
            }
        };
    }
    
    componentDidMount(){
        let canAccessClients = user.accessLevel.report.view;

        if(canAccessClients !== true){
            this.props.history.push("/");
        }else{  
            if(canAccessClients){
                this.props.loadClient({"complete":true});
            }

        }
    }
    
    componentWillReceiveProps(newprops){
        if(newprops.clients !== undefined){
            
            let clients = []

            let allUsers = 0;
            let userLogin = 0;
            let vMediaEnvironmentUsers = 0

            let totalTask = 0;
            let vMediaTask = 0;
            let vMediaEnvironmentTask = 0;

            let totalContract = 0
            let vMediaUserContract = 0
            let vMediaEnvironmentContract = 0

            let allEnvironment = 0;
            

            for (let i = 0; i < newprops.clients.length; i++) {
                const client = newprops.clients[i];
                
                let users = 0
                let tasks = 0
                let valueUser = 0
                let valueEnvironment = 0
                let countEnvironment = 0
                let usersActive = 0

                for (let n = 0; n < client.environments.length; n++) {
                    const cache = client.environments[n].cache
                    tasks += cache.schedules.total
                    users += cache.users.on + cache.users.off
                    usersActive += cache.users.on
                    userLogin += cache.users.on

                }

                if(users === 0){
                    valueUser = client.value;
                } else {
                    valueUser = parseFloat(client.value / users).toFixed(2);
                }

                if(client.environments.length === 0){
                    valueEnvironment = client.value;
                    countEnvironment = 1;
                }else{
                    valueEnvironment = parseFloat(client.value / client.environments.length).toFixed(2);
                    countEnvironment = client.environments.length
                }
                

                clients.push({
                    "id":client.id, 
                    "name":client.name,
                    "users":users,
                    "tasks":tasks,
                    "value":client.value,
                    "valueUser":valueUser,
                    "environments": client.environments.length,
                    "valueEnvironment" :valueEnvironment,
                    "usersActive":usersActive
                });


                allUsers += users;
                totalTask += tasks;
                totalContract += client.value;
                allEnvironment += countEnvironment;
                

            }

            vMediaTask = parseFloat(totalTask / allUsers).toFixed(2)
            vMediaEnvironmentTask = parseFloat(totalTask / allEnvironment).toFixed(2)
            vMediaUserContract =  parseFloat(totalContract / allUsers).toFixed(2)
            vMediaEnvironmentContract =  parseFloat(totalContract / allEnvironment).toFixed(2)
            vMediaEnvironmentUsers = parseFloat(allUsers / allEnvironment).toFixed(2)
            
            let kpis = {
                users:{
                    total:allUsers,
                    userLogin:userLogin,
                    vMediaEnvironmentUsers:vMediaEnvironmentUsers
                },
                tasks:{
                    total:totalTask,
                    vMediaTask:vMediaTask,
                    vMediaEnvironmentTask:vMediaEnvironmentTask
                },
                value:{
                    total:totalContract,
                    vMediaUser:vMediaUserContract,
                    vMediaEnvironment:vMediaEnvironmentContract
                }
            }

            this.setState({clients:clients});
            this.setState({kpis:kpis})
        }
    }
    
    render(){

          
        let columns = [
            { 
                Header: "Clientes",
                columns: [
                    {
                        Header: "Nome",
                        accessor: "name"
                    },
                    {
                        Header: "Usuários",
                        id: "users",
                        accessor: d => d.users
                    },
                    {
                        Header: "Ativos Hoje",
                        id: "usersActive",
                        accessor: d => d.usersActive
                    },
                    {
                        Header: "Valor",
                        id: "value",
                        accessor: d => d.value
                    },
                    {
                        Header: "Visitas Hoje",
                        id: "tasks",
                        accessor: d => d.tasks
                    },
                    {
                        Header: "Valor/usuário",
                        id: "valueUser",
                        accessor: d => d.valueUser,
                        Cell: row => (
                            <span>
                                <span style={{
                                    color: row.value > 39.9 ? '#ff2e00' : '#57d500',
                                    transition: 'all .3s ease'
                                    }}>
                                    &#x25cf;
                                </span> {
                                    row.value
                                }
                            </span>
                        )
                    },
                    {
                        Header: "Nº de Ambientes",
                        id: "environments",
                        accessor: d => d.environments
                    },
                    {
                        Header: "Valor/Ambiente",
                        id: "valueEnvironment",
                        accessor: d => d.valueEnvironment
                    }
                    
                ]
            }
        ];

        let kpis = this.state.kpis;

        return (


            <div id="client" className="pg">
                <div className='content'>

                    <div className="panel">
                        <h1>Clientes</h1>
                    </div>

                    <ul className="kpis">
                        <li>
                            <h3>Usuários</h3>
                            <p>Ativos <span>{kpis.users.total}</span></p>
                            <p>Logados hoje <span>{kpis.users.userLogin}</span></p>
                            <p>Média por ambiente <span>{kpis.users.vMediaEnvironmentUsers}</span></p>
                        </li>
                        <li>
                            <h3>Valores</h3>
                            <p>Total R$ <span>{kpis.value.total}</span></p>
                            <p>Média por usuário R$ <span>{kpis.value.vMediaUser}</span> </p>
                            <p>Média por ambiente R$ <span>{kpis.value.vMediaEnvironment}</span></p>
                        </li>
                        <li>
                        <h3>Visitas</h3>
                            <p>Total <span>{kpis.tasks.total}</span></p>
                            <p>Média por usuário <span>{kpis.tasks.vMediaTask}</span> </p>
                            <p>Média por ambiente <span>{kpis.tasks.vMediaEnvironmentTask}</span></p>
                        </li>
                    </ul>

                    <ReactTable data={this.state.clients} resizable={true} columns={columns} defaultPageSize={50} className="-striped -highlight" getTdProps = {(state, rowInfo, column, instance) => {

                    return {
                        onClick: (e, handleOriginal) => {
                            if(rowInfo !== undefined){
                                this.props.history.push("/clients/io/" + rowInfo.original.id)
                            }
                        }
                    }
                }}/>

                </div>
            </div>
            ); 
        }
    }
    
    const mapStateToProps = state => ({
        clients: state.client.list
    });
    
    const mapDispatchToProps = dispatch => {
        return {
            loadClient:(data) =>{
                dispatch(ClientApi.listClientByAccount(data))
            }
        }
    }
    
    export default connect(mapStateToProps, mapDispatchToProps)(ClientPage);


    