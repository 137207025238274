import React, {Component} from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import { Input , Button} from 'semantic-ui-react'
import environmentApi from '../../controllers/environmentApi'
import ClientApi from '../../controllers/clientApi'
import {toastr} from 'react-redux-toastr'
//import InputColor from 'react-input-color';


class EnvironmentsIOPage extends Component{
    
    constructor(props) {
        super(props);
        
        this.state = {
            name:"",
            alias:"",
            token:"",
            clients:[],
            clientId:0,
            clientSelected: null,
            logo:"",
            theme:"", 
            theme2:""
            
        };
        
        this.sendForm = this.sendForm.bind(this);
    }
    
    componentDidMount(){
        this.props.loadClient({"complete":false});
    }
    
    componentWillReceiveProps(newprops){
        
        if(newprops.clients !== undefined){
            
            let clients = []
            
            for (let i = 0; i < newprops.clients.length; i++) {
                const client = newprops.clients[i];
                clients.push({"value":client._id, "label":client.name});
            }
            
            this.setState({clients:clients});
        }

        if(newprops.newEnvironment !== undefined){

            this.setState({
                name:"",
                alias:"",
                token:"",
                clientSelected:null,
                logo:"",
            })

        }
        
    }
    
    sendForm(event){
        event.preventDefault();
        var headers = new Headers();
        headers.append('Content-Type', 'application/json'); 
        
        if(this.state.name === "" || this.state.alias === "" ||  this.state.clientSelected === undefined
         ||  this.state.clientSelected === null || this.state.logo === "" ){
            toastr.warning('Inválido', 'Preencha os campos corretamente');
        }else{
            
            let json = {
                "name":this.state.name,
                "alias":this.state.alias,
                "token":this.state.token,
                "clientId": this.state.clientSelected.value,
                "theme": {
                    "logo":this.state.logo,
                    "color":this.state.theme, 
                    "color2":this.state.theme2
                }
            }
            
            this.props.add(json);
        }
    }
    
    onChange(field, event) {
        this.setState({[field]:event.target.value});
    }
    
    handleChange = (field,selected) => {
        this.setState({[field]:selected});
    }
    
    render(){
        
        return (
            
            <div id="EnvironmentsIO" className="pg">
            <div className='content'>
            <form onSubmit={this.sendForm} method="post">
            <h1>Cadastro de Ambientes</h1>
            
            <Input placeholder="Apelido" 
            value={this.state.alias} 
            onChange={this.onChange.bind(this,'alias')}
            type="text" />
            
            <Input placeholder="Ambiente" 
            value={this.state.name} 
            onChange={this.onChange.bind(this,'name')} 
            type="text" />
            
            <Input placeholder="token" 
            value={this.state.token} 
            onChange={this.onChange.bind(this,'token')} 
            type="text" />
            
            <Select className="select-client" 
            value={this.state.clientSelected} 
            onChange={this.handleChange.bind(this,'clientSelected')} 
            options={this.state.clients} 
            placeholder="Cliente"/>
            
            <Input placeholder="Logo URL" 
            value={this.state.logo} 
            onChange={this.onChange.bind(this,'logo')} 
            type="text" />  
            
            <Button>Cadastrar</Button>
            </form>
            </div>
            </div>
            ); 
        }
    }
    
    const mapStateToProps = state => ({
        clients: state.client.list,
        newEnvironment:state.environment.new
    });
    
    const mapDispatchToProps = dispatch => {
        return {
            add:(data) => {
                dispatch(environmentApi.add(data));
            },
            loadClient:(data) =>{
                dispatch(ClientApi.listClientByAccount(data))
            }
        }
    }
    
    export default connect(mapStateToProps, mapDispatchToProps)(EnvironmentsIOPage);