import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import CountUp from 'react-countup';

const ViewProgress = styled.div`
    width: ${props => props.progress + '%'};
    will-change: transform;
    transition-property: all;
    transition-duration: ${props => props.durration}s;
    transition-timing-function: cubic-bezier(.55,0,.1,1);
`;

export default function ComponentProgress({value, total, progress, options}) {

    const defaultOptions = {
        display: true,
        duration: 2,
    }

    const [_progress, setProgress] = useState((progress === undefined) ? 0 : progress)
    const [_options] = useState((options === undefined) ? defaultOptions : options)

    useEffect(() => {
        const progress = parseInt((value / total) * 100)
        setProgress(isNaN(progress) ? 0 : progress)
    },[value, total])

    const display = (_options.display) ? <CountUp start={0} end={_progress} duration={_options.duration} suffix="%"></CountUp> : null

    return (
        <div className="component-progress" >
            <div className="progress-view">
                <ViewProgress className="progress" 
                progress={_progress}
                durration={_options.duration}></ViewProgress>
            </div>
            {display}
        </div>
    ); 
}
    
