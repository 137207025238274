import React, {Component} from 'react';
import ComponentInput from '../../components/component-input';
import { connect } from 'react-redux';
//import Select from 'react-select';
const API_URL = process.env.API_URL;

class ManagerIOPage extends Component{
    
    constructor(props) {
        super(props);
        
        this.state = {
            name:""
        };
    }
    
    componentDidMount(){
      
    }
    
    componentWillReceiveProps(newprops){
       
    }

    sendForm(event){
        event.preventDefault();
        var headers = new Headers();
        headers.append('Content-Type', 'application/json'); 

        var params = { 
                method: 'POST',
                headers: headers,
                cache: 'no-cache',
                body: JSON.stringify({
                    name: this.state.name,
                })
            };
        
        const url = API_URL + "/clients";
        
        fetch(url,params)
        .then(function(response) {
            response.json().then(function(data) {
                console.log(data.msg);
            });
        })
        .catch(function(error) {
            console.log(error); 
        });
    }

    onChange(field, event) {
        this.setState({[field]:event.target.value});
    }
    
    render(){

        return (
            <div id="ManagerIO" className="pg">
                <div className='content'>
                    <form onSubmit={this.sendForm} method="post">
                        <h1>Cadastro - Gerente de Conta</h1>
                        <ComponentInput legend='Nome' placeholder='Nome' type='text' value={this.state.name} onChange={this.onChange.bind(this,'name')}/>
                        <ComponentInput legend='Email' placeholder='Email' type='email' value={this.state.name} onChange={this.onChange.bind(this,'email')}/>
                        <ComponentInput legend='Senha' placeholder='Senha' type='password' value={this.state.name} onChange={this.onChange.bind(this,'password')}/>
                        <input type='submit' value='Criar'/>
                    </form>
                </div>
            </div>
            ); 
        }
}
    
const mapStateToProps = state => ({
    
});

const mapDispatchToProps = dispatch => {
    return {
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagerIOPage);