import {load, openConfig, openSearch, openNew, openUserMenu, selectedClient} from '../actions/application'
//import ls from 'local-storage'
//import checkSession from '../utils/session'
import user from '../utils/user'
import {toastr} from 'react-redux-toastr'
export default class ApplicationApi {
    
    static load() {
        return dispatch => {
            let obj = {}
            
            obj.account = {
                url:"https://s3-sa-east-1.amazonaws.com/99freelas/profile/210x210/Laivon.png?m=1",
                name:"Laivon"
            }
            
            dispatch(load(obj));
        }
    }
    
    static openConfig(isOpen = true){
        return dispatch => { return dispatch(openConfig(isOpen)); }
    }
    
    static changeClient(client) {
        if(client !== undefined && client !== null){
            toastr.success('Alterando Cliente', "Visualizando: " + client.name);
        }else{
            toastr.error('Alterando Cliente', "Cliente Indefinido");
        }
        return dispatch => {
            return dispatch(selectedClient(client))
        }
    }
    
    static openSearch(isOpen = true){
        if(user.accessLevel.tools.view){
            return dispatch => { return dispatch(openSearch(isOpen)); }
        } else {
            toastr.warning('Permissões', 'Você não possuí acesso a esssa funcionalidade');
            return dispatch => { return dispatch(openConfig(false)); };
        }
    }
    
    static openNew(isOpen = true){
        if(user.accessLevel.tools.view){
            return dispatch => { return dispatch(openNew(isOpen)); }
        } else {
            toastr.warning('Permissões', 'Você não possuí acesso a esssa funcionalidade');
            return dispatch => { return dispatch(openConfig(false)); };
        }
    }
    
    static openUserMenu(isOpen = true){
        return dispatch => {  return dispatch(openUserMenu(isOpen)); }
    }
    
}